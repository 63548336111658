import request from '@/utils/request'

// 查询单日收益管理列表
export function listDayAmount(query) {
  return request({
    url: '/work/DayAmount/list',
    method: 'get',
    params: query
  })
}

// 查询单日收益管理详细
export function getDayAmount(id) {
  return request({
    url: '/work/DayAmount/' + id,
    method: 'get'
  })
}

// 新增单日收益管理
export function addDayAmount(data) {
  return request({
    url: '/work/DayAmount',
    method: 'post',
    data: data
  })
}

// 修改单日收益管理
export function updateDayAmount(data) {
  return request({
    url: '/work/DayAmount/update',
    method: 'post',
    data: data
  })
}


// 删除单日收益管理
export function delDayAmount(id) {
  return request({
    url: '/work/DayAmount/remove/' + id,
    method: 'get'
  })
}

// 导出单日收益管理
export function exportDayAmount(query) {
  return request({
    url: '/work/DayAmount/export',
    method: 'get',
    params: query
  })
}